import { TranscriptionRatingApi } from "@/api/transcription-rating.api";
import MainLayout from "@/components/doctor/main-layout";
import StatusBadge from "@/components/status-badge";
import { isUploadedInLastSevenDays } from "@/utils/isUploadedInLastSevenDays";
import { useQuery } from "@apollo/client";
import Pusher from "pusher-js";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AudioWidget from "./audio-widget";
import EmailTab from "./email-tab";
import { GET_TRANSCRIPTION_QUERY } from "./queries";
import SoapTab from "./soap-tab";
import TranscriptionRating from "./transcription-rating";

import {
  TranscriptionData,
  TranscriptionRatingPayload,
  TranscriptionRatingResponse,
  TranscriptionVars,
} from "./types";

enum Tab {
  Soap = "soap",
  Email = "email",
}

const TranscriptionPage: React.FC = () => {
  const [currentTab, setCurrentTab] = useState<Tab>(Tab.Soap);

  const { transcriptionId } = useParams<{ transcriptionId: string }>();

  const { data, refetch } = useQuery<TranscriptionData, TranscriptionVars>(
    GET_TRANSCRIPTION_QUERY,
    {
      fetchPolicy: "no-cache",
      variables: {
        transcriptionId: transcriptionId!,
      },
    },
  );

  const handleRatingSubmit = (
    score: TranscriptionRatingPayload["score"],
  ): Promise<TranscriptionRatingResponse> => {
    if (!data?.transcription.id)
      return Promise.reject(new Error("No transcription ID"));
    return TranscriptionRatingApi.create({
      transcriptionId: data?.transcription?.id,
      data: { score },
    });
  };

  const handleFeedbackSubmit = (
    review: TranscriptionRatingPayload["review"],
  ): Promise<TranscriptionRatingResponse> => {
    if (!data?.transcription.id)
      return Promise.reject(new Error("No transcription ID"));
    return TranscriptionRatingApi.update({
      transcriptionId: data?.transcription?.id,
      data: { review },
    });
  };

  const shouldDisplayTranscriptionRating = (): boolean => {
    const transcription = data?.transcription;
    if (!transcription || !transcription.uploadedAt) {
      return false;
    }
    return (
      !transcription.hasRating &&
      isUploadedInLastSevenDays(transcription.uploadedAt)
    );
  };

  useEffect(() => {
    const pusher = new Pusher("d10606873286053e4a22", {
      cluster: "us3",
    });

    const channel = pusher.subscribe(`transcription-${transcriptionId}`);

    channel.bind("updated", function () {
      refetch();
    });

    return () => {
      channel.unbind("updated");
      pusher.unsubscribe(`transcription-${transcriptionId}`);
    };
  }, [transcriptionId, refetch]);

  if (!data) {
    return null;
  }

  return (
    <MainLayout>
      <div className="p-8">
        <Link
          to="/records"
          className="text-xs text-indigo-500 hover:text-indigo-600"
        >
          &larr; Back
        </Link>
        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between">
          <div className="w-full lg:w-2/3">
            <div className="flex flex-row items-end justify-start">
              <h1 className="text-xl font-bold mr-4">
                {data.transcription.friendlyName}
              </h1>
              <div>
                <StatusBadge status={data.transcription.status} />
              </div>
            </div>
            <h3 className="text-medium font-medium text-slate-500">
              {data.transcription.friendlyUploadedAt}
            </h3>
            <br />
            <p className="text-slate-600">
              <span className="font-medium text-slate-800">Recorded At</span>:{" "}
              {data.transcription.hospitalName}
            </p>
            <p className="text-slate-600">
              <span className="font-medium text-slate-800">Recorded By</span>:{" "}
              {data.transcription.userFullName}
            </p>
          </div>
          {shouldDisplayTranscriptionRating() && (
            <div className="w-full lg:w-1/3 mt-6 lg:mt-0">
              <div className="flex flex-row items-end justify-start">
                <TranscriptionRating
                  onSubmitRating={handleRatingSubmit}
                  onSubmitFeedback={handleFeedbackSubmit}
                />
              </div>
            </div>
          )}
        </div>
        <AudioWidget transcriptionId={transcriptionId!} />
        <br />
        <div>
          <div className="sm:block">
            <div className="border-b border-slate-200">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                <a
                  onClick={() => setCurrentTab(Tab.Soap)}
                  className={`${
                    currentTab === Tab.Soap
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-slate-500 hover:border-slate-300 hover:text-slate-700"
                  } whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer`}
                >
                  SOAP
                </a>
                <a
                  onClick={() => setCurrentTab(Tab.Email)}
                  className={`${
                    currentTab === Tab.Email
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-slate-500 hover:border-slate-300 hover:text-slate-700"
                  } whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer`}
                >
                  Client Email
                </a>
              </nav>
            </div>
          </div>
        </div>
        <div className="py-8">
          {currentTab === Tab.Soap && (
            <SoapTab transcription={data.transcription} refetch={refetch} />
          )}
          {currentTab === Tab.Email && (
            <EmailTab transcription={data.transcription} refetch={refetch} />
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default TranscriptionPage;

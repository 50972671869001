import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { ThumbsDown, ThumbsUp } from "lucide-react";
import React, { useState } from "react";
import {
  TranscriptionRatingPayload,
  TranscriptionRatingResponse,
} from "../types";

type RatingProps = {
  onSubmitRating: (
    score: TranscriptionRatingPayload["score"],
  ) => Promise<TranscriptionRatingResponse>;
  onSubmitFeedback: (
    feedback: TranscriptionRatingPayload["review"],
  ) => Promise<TranscriptionRatingResponse>;
};

enum Step {
  Rate = "rate",
  Feedback = "feedback",
  Thanks = "thanks",
  Error = "error",
}

const TranscriptionRating: React.FC<RatingProps> = ({
  onSubmitRating,
  onSubmitFeedback,
}) => {
  const [step, setStep] = useState<Step>(Step.Rate);
  const [selectedRating, setSelectedRating] = useState<
    TranscriptionRatingPayload["score"] | null
  >(null);
  const [feedback, setFeedback] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleRatingSubmit = async (value: number) => {
    setIsSubmitting(true);
    setSelectedRating(value);
    try {
      await onSubmitRating(value);
      setStep(Step.Feedback);
    } catch {
      setStep(Step.Error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFeedbackSubmit = () => {
    onSubmitFeedback(feedback);
    setStep(Step.Thanks);
  };

  const SuccessHeader: React.FC<{ children: React.ReactNode }> = ({
    children,
  }) => (
    <div className="flex justify-left items-center space-x-2 mt-2">
      <ThumbsUp className="w-5 h-5 text-green-500" />
      <p className="text-base font-bold">{children}</p>
    </div>
  );

  return (
    <Card className="w-[350px] h-[140px] mx-0 p-3 rounded-lg flex flex-col justify-between shadow-none">
      {step === Step.Rate && (
        <CardContent className="flex flex-col items-center justify-center text-left space-y-3">
          <p className="text-gray-700 text-base">
            This output makes it easy for me to provide better care to my
            patients.
          </p>
          <div className="flex justify-center space-x-4">
            {[1, 2, 3, 4, 5].map((value) => (
              <button
                key={value}
                aria-label={`Rate ${value} score`}
                disabled={isSubmitting}
                onClick={() => handleRatingSubmit(value)}
                className={`w-11 h-11 rounded-sm border-2 flex items-center justify-center text-sm font-medium transition ${
                  selectedRating === value
                    ? "bg-blue-500 text-white border-blue-500"
                    : "bg-white text-gray-600 border-gray-300 hover:bg-gray-100"
                }`}
              >
                {value}
              </button>
            ))}
          </div>
        </CardContent>
      )}

      {step === Step.Feedback && (
        <CardContent className="flex flex-col justify-between">
          <SuccessHeader>Feedback submitted</SuccessHeader>
          <div className="flex items-center justify-between mb-2 mt-1">
            <p className="text-gray-700 text-sm">
              (Optional) What could improve it?
            </p>
            <Button
              onClick={handleFeedbackSubmit}
              className="ml-2 px-2 py-1 text-xs md-2"
              size="sm"
              disabled={!feedback.trim()}
            >
              Send
            </Button>
          </div>
          <textarea
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            placeholder="Your feedback here..."
            className="resize-none w-full max-h-9 text-xs p-2 border border-gray-300 rounded-md"
          />
        </CardContent>
      )}

      {step === Step.Thanks && (
        <CardContent className="flex flex-col justify-between text-left">
          <SuccessHeader>Feedback submitted</SuccessHeader>
          <div className="flex-grow flex items-center justify-center mt-4">
            <p className="text-gray-700 text-base">
              We aim to continually improve. Your feedback helps us. Thanks.
            </p>
          </div>
        </CardContent>
      )}

      {step === Step.Error && (
        <CardContent className="flex flex-col justify-between text-left">
          <div className="flex justify-left items-center space-x-2 mb-4 mt-2">
            <ThumbsDown className="w-5 h-5 text-red-500" />
            <p className="text-base font-bold">Feedback submission failed</p>
          </div>
          <div className="flex-grow flex items-center justify-center">
            <p className="text-gray-700 text-base">
              Oops! We couldn’t submit your feedback. Please try again later.
            </p>
          </div>
        </CardContent>
      )}
    </Card>
  );
};

export default TranscriptionRating;

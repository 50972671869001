import { TranscriptionRatingResponse } from "@/pages/doctor/transcription/types";
import { apiFetch } from "./utils";

type CreateTranscriptionRatingData = {
  score: number;
};

type UpdateTranscriptionRatingData = {
  review: string;
};

const API_BASE_URL = import.meta.env.VITE_REACT_APP_API_URL;

const buildTranscriptionRatingUrl = (transcriptionId: string): string =>
  `${API_BASE_URL}/v1/transcriptions/${transcriptionId}/transcription_rating`;

export class TranscriptionRatingApi {
  static create({
    transcriptionId,
    data,
  }: {
    transcriptionId: string;
    data: CreateTranscriptionRatingData;
  }): Promise<TranscriptionRatingResponse> {
    const url = buildTranscriptionRatingUrl(transcriptionId);
    return apiFetch(url, {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  static update({
    transcriptionId,
    data,
  }: {
    transcriptionId: string;
    data: UpdateTranscriptionRatingData;
  }): Promise<TranscriptionRatingResponse> {
    const url = buildTranscriptionRatingUrl(transcriptionId);
    return apiFetch(url, {
      method: "PATCH",
      body: JSON.stringify(data),
    });
  }
}
